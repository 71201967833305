import { I18nextContext, Link } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { cn } from '../lib/helpers';

import * as styles from './project-preview.module.css';
import { responsiveTitle3 } from './typography.module.css';

function ProjectPreview({ images, slug, title }) {
  const mainImage = images?.[0];
  const { language } = React.useContext(I18nextContext);

  return (
    <Link className={styles.root} to={`/project/${slug.current}`}>
      <div className={styles.leadMediaThumb}>
        {mainImage && mainImage.asset && (
          <img src={mainImage.asset.url} alt={mainImage.caption} />
        )}
      </div>
      <h3 className={cn(responsiveTitle3, styles.title)}>{title[language]}</h3>
    </Link>
  );
}

export default ProjectPreview;
