import React from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'react-i18next';
import { filterOutProjectsWithoutSlugs } from '../lib/helpers';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import ProjectPreviewGrid from '../components/project-preview-grid';
import SEO from '../components/seo';
import Layout from '../containers/layout';

export const query = graphql`
  query IndexPageQuery($language: String!) {
    site {
      siteMetadata {
        description
        siteUrl
        title
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projects: allSanityProject(limit: 12) {
      nodes {
        id: _id
        title {
          en
          bg
        }
        description {
          en
          bg
        }
        slug {
          current
        }
        category {
          _rawTitle
        }
        images {
          asset {
            url
          }
          caption
        }
        body {
          en {
            children {
              text
              marks
            }
            list
            style
          }
          bg {
            children {
              marks
              text
            }
            list
            style
          }
        }
      }
    }
  }
`;

const IndexHeader = () => (
  <div className="relative mt-24 max-w-md mx-auto px-4 sm:max-w-3xl sm:mt-32 sm:px-6 lg:max-w-7xl lg:px-8">
    <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
      <Trans>Architecture Studio Avangard</Trans>
    </h1>
  </div>
);

const IndexPage = (props) => {
  const { data, errors } = props;
  const { t } = useTranslation();
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site?.siteMetadata;
  const projectNodes =
    data?.projects?.nodes.filter(filterOutProjectsWithoutSlugs) ?? [];
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout headerChildren={<IndexHeader />}>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
        bodyAttr={{
          class: 'leading-normal tracking-normal',
        }}
      />
      <Container>
        <h1 hidden>
          <Trans>Welcome to {site.title}</Trans>
        </h1>
        {projectNodes && (
          <ProjectPreviewGrid
            title={t('Latest projects')}
            nodes={projectNodes}
            browseMoreHref="/projects/"
          />
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
